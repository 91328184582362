.modal-recommend {
  position: absolute;
  display: flex;
  justify-content: center;
  inset: 0;
  z-index: 99999;
}

.close {
  position: fixed;
  top: 50%;
  right: 0;
  border-radius: 0;
  &-toggle {
    position: absolute;
    left: -44px !important;
    border-radius: 0 !important;
  }
  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 100px;
    min-height: 100px;
    background: #102c48;

    &-func:not(:last-of-type) {
      border-bottom: 1px solid #666;
    }

    &-func {
      padding: 5px 0;
      width: 100%;
      text-align: center;
      cursor: pointer;
      &:hover {
        background: #ccc;
      }
    }
  }
}

.sub-title {
  font-size: 1rem;
  text-align: center;
  border-bottom: 1px solid #000;
}

.modal-detail {
  z-index: 1;
  position: absolute;
  border-radius: 10px;
  &__inner {
    background: #1e293b;
    padding: 10px;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background: #000;
  }

  tr > td {
    color: #0ea5e9;
  }

  .btn-back {
    border-radius: 0;
  }
}

.hide {
  display: none !important;
}

.title,
tr td:nth-last-child(1) {
  color: #8b5cf6;
  font-weight: 500;
}
.title {
  font-size: 1.8rem;
}

.overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
}

//search-movies_modal
.search-movies {
  &__modal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    width: 100vw;
    height: 100vh;
  }

  &__input {
    background-color: #fff;
    border-color: #dbdbdb;
    border-radius: 4px 0 0 4px;
    // box-shadow: inset 0 0.0625em 0.125em #0a0a0a0d;
    color: #363636;
    max-width: 100%;
    min-height: 50px;
    width: 60%;
  }

  &__close {
    position: absolute;
    right: 50px;
    width: 100px;
  }

  &__btn {
    min-height: 50px;
    border-radius: 0 !important;
  }
}

.table {
  background: #fff;
}

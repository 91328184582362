.horizontal {
  width: 100%;
  height: 1px;
  background: #000;
}

.form-label {
  color: #000;
}

.css-u2wfy7,
.css-kqwcd2 {
  position: absolute;
  align-items: center;
  left: 265px;
  top: 25px;
}

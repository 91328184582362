.wapper {
  position: relative;
  margin-top: 50px;
  width: 100%;
}

.section {
  position: relative;
  padding: 48px;
  display: flex;
  justify-content: center;
}

.container {
  margin: 0 40px;

  @media screen and (max-width: 767.98px) {
    margin: 0;
  }
}
.field:not(:last-child) {
  margin-bottom: 1.2rem;
}

.content {
  display: flex;
  justify-content: center;
  text-align: center;
}

//resposive for container

@media screen and (max-width: 767.98px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 100%;
  }
}

@media screen and (max-width: 1023.98px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 100%;
  }

  .section {
    padding: 36px 12px;
  }
}

@media screen and (min-width: 1024px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 960px;
  }
}

@media screen and (min-width: 1216px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1152px;
  }
}

@media screen and (min-width: 1408px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

.content {
  min-height: 100px;
}

.has-text-grey {
  color: #7a7a7a !important;
}

.column {
  @media screen and (min-width: 1216px) {
    width: 33%;
  }
  padding: 12px;

  .field {
    margin-bottom: 12px;
  }

  .control {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    clear: both;
    font-size: 1rem;
    position: relative;
    text-align: inherit;
  }

  input:not(.remember) {
    -webkit-appearance: none;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.5em;
    justify-content: flex-start;
    line-height: 1.5;
    position: relative;
    vertical-align: top;
    background-color: #fff;
    border-color: #dbdbdb;
    border-radius: 4px;
    color: #363636;
    -webkit-box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
    box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
    max-width: 100%;
    width: 100%;
    padding: 11px 17px;
    font-size: 2.4rem;
  }
}

.box {
  background-color: #363636;
  border-radius: 6px;
  -webkit-box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  display: block;
  padding: 20px;
}

.checkbox,
.radio {
  cursor: pointer;
  display: inline-block;
  line-height: 1.25;
  position: relative;
}

.button {
  width: 100%;
  font-size: 2.4rem;
  height: 2.5em;
  line-height: 1.5;

  color: #363636;
  cursor: pointer;

  white-space: nowrap;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  background-color: #fff;
  border-color: #dbdbdb;
  border-width: 1px;
  -webkit-box-shadow: none;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 11px 24px;
  position: relative;
  vertical-align: top;

  &.is-info {
    margin-top: 12px;
    background-color: #3e8ed0;
    border-color: transparent;
    color: #fff;
  }
}

.field {
  .checkbox:hover,
  .radio:hover {
    color: #ffe08a;
    cursor: pointer;
  }
  label {
    display: flex;
    gap: 5px;
    text-align: start;
    &:hover {
      color: #ffe08a;
      cursor: pointer;
    }
  }
}
